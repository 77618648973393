import React, { SyntheticEvent, useState } from "react";
import {
  createStyles,
  Table,
  Anchor,
  ScrollArea,
  Grid,
  Button,
  Divider,
  ActionIcon,
  Pagination,
} from "@mantine/core";
import { Edit, Archive } from "tabler-icons-react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllChannelNames } from "../../queries/channelQueries";
import {
  deletePost,
  getAllFeeds,
  getAllFeedsFilter,
  getCategories,
  getHashtags,
} from "../../queries/feedQueries";
import { FeedProps } from "../../typing/feedTypes";
import FeedForm from "./FeedForm";
import dayjs from "dayjs";
const useStyles = createStyles((theme) => ({
  progressBar: {
    "&:not(:first-of-type)": {
      borderLeft: `3px solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));

const FeedView = () => {
  const { classes, theme } = useStyles();
  const [isOpenForm, setOpenForm] = useState(false);
  const [updateData, setUpdateData] = useState<FeedProps | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const queryClient = useQueryClient();
  const { isLoading: isLoadingChannels, data: channelList } = useQuery(
    "channelsList",
    getAllChannelNames,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: isLoadingHashtags, data: hashtagList } = useQuery(
    "hashtags",
    getHashtags,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: isLoadingCat, data: categoryList } = useQuery(
    "categories",
    getCategories,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading, data: feedData = [] } = useQuery(["feeds",page], getAllFeedsFilter, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const onUpdateData = (data: any) => {
    let payload = Object.assign({}, data);

    payload.post_image = payload.post_image;

    // payload.flash_card = JSON.parse(payload.flash_card);
    payload.audio = payload.audio;
    // payload.exam = JSON.parse(payload.exam);
    // payload.poll = JSON.parse(payload.poll);
    // payload.quiz = JSON.parse(payload.quiz);
    // payload.quiz_fight = JSON.parse(payload.quiz_fight);

    payload.video = payload.video;
    payload.youtube = payload.youtube;

    setUpdateData(payload);
    setOpenForm(true);
  };

  const { mutate: mutDeleteFeed } = useMutation(deletePost, {
    onSuccess: (newData) => {
      queryClient.invalidateQueries();
    },
  });
  const onRestForm = () => {
    setOpenForm(false);
    setUpdateData(null);
  };
  const totalPages = Math.ceil((feedData?.length ?? 0) / rowsPerPage);

  const rows =
    feedData &&
    feedData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row: FeedProps) => {
        return (
          <tr key={row.post_setup.id}>
            <td>{row.post_setup.channel_name}</td>
            <td>{row.post_setup.hashtags}</td>
            <td>{row.post_setup.post_category}</td>
            <td>{row.post_setup.university.name}</td>
            <td>{row.post_setup.course.name}</td>
            <td>{row.post_setup.stream.name}</td>
            <td>{row.post_setup.semester.name}</td>
            <td>{row.post_setup.whatsapp_join_status}</td>
            <td>
              <Anchor<"a">
                size="sm"
                onClick={(event) => event.preventDefault()}
              >
                {row.post_setup.link_open}
              </Anchor>
            </td>
            <td>{row.post_setup.description}</td>
            <td>
              <Anchor<"a">
                size="sm"
                onClick={(event) => event.preventDefault()}
              >
                {row.post_setup.link}
              </Anchor>
            </td>

            <td>{row.post_setup.share_text}</td>
            <td>{row.post_setup.share_image}</td>
            <td>{row.post_setup.share_link}</td>
            <td>{row.post_setup.post_type}</td>
            <td>{row.post_setup.listing_order}</td>
            <td>{row.post_setup.publish?.toString()}</td>
            <td>{row.post_setup.channel_subscribed?.toString()}</td>
            <td>{row.post_setup.channel_thumbnail}</td>
            <td>{dayjs(row.post_setup.created_at).format("DD-MM-YYYY")}</td>
            <td>{row.post_image?.toString()}</td>
            <td>{row.youtube?.thumbnail}</td>
            <td>{row.youtube?.youtube_link}</td>
            <td>{row.video?.thumbnail}</td>
            <td>{row.video?.video_link}</td>
            <td>{row.audio?.thumbnail}</td>
            <td>{row.audio?.audio_link}</td>
            <td>{row.audio?.duration}</td>
            <td>{row.post_setup?.like_base_value}</td>
            <td>{row.post_setup?.share_base_value}</td>
            <td>{row.post_setup?.save_base_value}</td>

            <td>
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <ActionIcon
                    title="Update"
                    onClick={() => onUpdateData({ ...row })}
                  >
                    <Edit size={25} strokeWidth={2} color={"green"} />
                  </ActionIcon>
                </Grid.Col>
                <Grid.Col span={1}>
                  <ActionIcon
                    title="Delete"
                    onClick={() => mutDeleteFeed(row.post_setup.id as number)}
                  >
                    <Archive size={25} strokeWidth={2} color={"red"} />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            </td>
          </tr>
        );
      });

  if (isLoading) {
    // TODO loader on data loading
    return null;
  }
  return (
    <>
      <Grid justify={"flex-end"}>
        <Grid.Col lg={1}>
          <Button
            variant="light"
            onClick={(e: SyntheticEvent) => setOpenForm(true)}
          >
            Create
          </Button>
        </Grid.Col>

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 700, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing={"xl"}
            >
              <thead>
                <tr>
                  <th>Channel</th>
                  <th>Hashtags</th>
                  <th>Post Category</th>
                  <th>Univeristy</th>
                  <th>Course</th>
                  <th>Stream</th>
                  <th>Semester</th>
                  <th>Whatsapp Join Status</th>
                  <th>Link Open</th>
                  <th>Description</th>
                  <th>Link</th>
                  <th>Share Text</th>
                  <th>Share Link</th>
                  <th>Share Image</th>
                  <th>Post Type</th>
                  <th>Listing Order</th>
                  <th>Publish</th>
                  <th>Channel Subscribed</th>
                  <th>Channel Thumbnail</th>
                  <th>Created At</th>
                  <th>Post Image</th>
                  <th>Youtube Thumbnail</th>
                  <th>Youtube Link</th>
                  <th>Video Thumbline</th>
                  <th>Video Link</th>
                  <th>Audio Thumbline</th>
                  <th>Audio Link</th>
                  <th>Audio Duration</th>
                  <th>Base Likes</th>
                  <th>Base Shares</th>
                  <th>Base Saves</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col>
          <Pagination
            disabled={!feedData.length}
            position="right"
            page={page + 1}
            onChange={(page: number) => setPage(page - 1)}
            total={totalPages}
          />
        </Grid.Col>
      </Grid>
      <FeedForm
        isOpenModal={isOpenForm}
        onReset={onRestForm}
        data={updateData}
        channels={channelList?.data}
        hashtagData={hashtagList?.data}
        categories={categoryList?.data}
      />
    </>
  );
};

export default FeedView;
