import {
  ActionIcon,
  Button,
  Divider,
  Grid,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { Archive } from "tabler-icons-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FeedDataProps } from "../../typing/feedTypes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createNewPost, updatePost } from "../../queries/feedQueries";
import { getAllData, getAllUniversityDetails } from "../university/queries";

const initalStates = {
  image_url: "",
  postImageData: [] as string[],
  youtubeData: {
    thumbnail: "",
    youtube_link: "",
  },
  videoData: {
    thumbnail: "",
    video_link: "",
  },
  audioData: {
    thumbnail: "",
    audio_link: "",
    duration: "",
  },
};
const postType = [
  { value: "normal", label: "Normal" },
  { value: "youtube", label: "Youtube" },
  { value: "flash_card", label: "Flash Card" },
  { value: "video", label: "Video" },
  { value: "quiz", label: "Quiz" },
  { value: "quizfight", label: "Quizfight" },
  { value: "whatsapp", label: "Whatsapp" },
  { value: "extra1", label: "Extra 1" },
  { value: "extra2", label: "Extra 2" },
];

const FeedForm = (props: any) => {
  const queryClient = useQueryClient();
  const [{ image_url, postImageData, youtubeData, videoData, audioData }, setState] =
    useState(initalStates);
  const [streamData, setStreamData] = useState<any[] | []>();

  useEffect(() => {
    getData({
      universities: [],
      courses: [],
      streams: [],
      semesters: [],
      subjects: [],
      limit: 1000,
      offset: 0,
    });
  }, []);

  const { mutate: getData } = useMutation(getAllUniversityDetails, {
    onSuccess: (result: any) => {
      setStreamData(result?.data);
    },
  });

  const { mutate: mutCreatePost } = useMutation(createNewPost, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });

  const { mutate: mutUpdatePost } = useMutation(updatePost, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  const resetState = () => {
    setState({ ...initalStates });
  };
  useEffect(() => {
    if (props.data) {
      let feedData = { ...props.data.post_setup };
      console.log(feedData);
      feedData.university = feedData.university.id
      feedData.course = feedData.course.id
      feedData.stream = feedData.stream.id
      feedData.semester = feedData.semester.id

      const hashstagObjec = {
        hashtags: feedData.hashtags.split(","),
        post_category: feedData.post_category.split(","),
        // university: feedData.university.split(","),
        // course: feedData.course.split(","),
        // stream: feedData.stream.split(","),
        // semester: feedData.semester.split(","),
      };
      const bind_Data = Object.assign(feedData, hashstagObjec);

      setValues(feedData);
      setState((prevState) => ({
        ...prevState,
        flashCardData: props.data.flash_card,
        postImageData: props.data.post_image,
        quizFightData: props.data.quiz_fight,
        quizData: props.data.quiz,
        examData: props.data.exam,
        pollData: props.data.poll,
        youtubeData: props.data.youtube,
        videoData: props.data.video,
        audioData: props.data.audio,
      }));
    }

    return () => {
      resetState();
    };
  }, [props.data]);

  const initialValues = {
    id: undefined,
    channel_id: null,
    hashtags: [],
    post_category: [],
    channel_thumbnail: "",
    link_open: "",
    channel_name: "",
    description: "",
    link: "",
    share_text: "",
    share_link: "",
    post_type: "",
    channel_subscribed: false,
    whatsapp_join_status: false,
    created_at: new Date(),
    share_image: "",
    button_text: "",
    button_color: "",
    image_size: "",
    listing_order: undefined,
    publish: false,
    university: "",
    course: "",
    stream: "",
    semester: "",
    like_base_value: undefined,
    share_base_value: undefined,
    save_base_value: undefined,
  } as Omit<
    FeedDataProps,
    | "post_image"
    | "flash_card"
    | "quiz_fight"
    | "quiz"
    | "exam"
    | "poll"
    | "youtube"
    | "video"
    | "audio"
  >;

  const form = useForm({
    initialValues,
    validate: {},
  });
  const onFieldChange = (name: string, value: any) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const onSetNormalPosts = () => {
    setState((prevState) => ({
      ...prevState,
      postImageData: [...postImageData, image_url],
      image_url: "",
    }));
  };

  const onRemoveNormalPost = (url: string) => {
    setState((prevState) => ({
      ...prevState,
      postImageData: postImageData.filter((item) => item !== url),
    }));
  };

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  const findDistinct = (array: any[], field: string) => {
    if (array.length) {
      const uniqueIds: any = new Set(array.map((obj) => obj[field]));
      const distinctArray = [...uniqueIds].map((id) =>
        array.find((obj) => obj[field] === id)
      );

      return distinctArray;
    } else {
      return [];
    }
  };

  const findUniversities = () => {
    if (streamData?.length) {
      let distUniv: any = findDistinct(streamData, "university_id");
      console.log(distUniv);

      if (distUniv?.length) {
        return distUniv?.map((a: any) => {
          return {
            ...a,
            value: a.university_id,
            label: a.university_name,
          };
        });
      }
    } else {
      return [];
    }
  };
  const findCourses = () => {
    if (values.university) {
      let unv: any = streamData?.filter(
        (u) => u.university_id === values.university
      );
      let distCrs: any = findDistinct(unv, "course_id");
      if (distCrs?.length) {
        return distCrs?.map((a: any) => {
          return {
            ...a,
            value: a.course_id,
            label: a.course_name,
          };
        });
      }
    } else {
      return [];
    }
  };
  const findStreams = () => {
    if (values.course) {
      let unv: any = streamData?.filter(
        (u) => u.university_id === values.university
      );
      let crs: any = unv?.filter((u: any) => u.course_id === values.course);

      let distStm: any = findDistinct(crs, "stream_id");
      if (distStm?.length) {
        return distStm?.map((a: any) => {
          return {
            ...a,
            value: a.stream_id,
            label: a.stream_name,
          };
        });
      }
    } else {
      return [];
    }
  };
  const findSemesters = () => {
    if (values.stream) {
      let unv: any = streamData?.filter(
        (u) => u.university_id === values.university
      );
      let crs: any = unv?.filter((u: any) => u.course_id === values.course);
      let str: any = crs?.filter((u: any) => u.stream_id === values.stream);

      let distSem: any = findDistinct(str, "semester_id");
      if (distSem?.length) {
        return distSem?.map((a: any) => {
          return {
            ...a,
            value: a.semester_id,
            label: a.semester_name,
          };
        });
      }
    } else {
      return [];
    }
  };

  console.log(values);

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.onReset();
        resetState();
      }}
      title={props.data ? "Update Post" : "Create New Post"}
      centered
      size={"xl"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          const hashtags = payload.hashtags;
          const post_category = payload.post_category;
          const university = payload.university;
          const course = payload.course;
          const stream = payload.stream;
          const semester = payload.semester;

          payload.hashtags = hashtags.toString();
          payload.post_category = post_category.toString();
          payload.post_category.toString();
          payload.university = [university];
          payload.course = [course];
          payload.stream = [stream];
          payload.semester = [semester];

          payload.channel_name = props.channels.find(
            (item: { channel_id: Number; name: string }) =>
              item.channel_id === payload.channel_id
          ).name;
          payload.channel_thumbnail = props.channels.find(
            (item: { channel_id: Number; name: string; thumbnail: string }) =>
              item.channel_id === payload.channel_id
          ).thumbnail;

          let post_payload: any = Object.assign(payload, {
            post_image: postImageData,
            youtube: youtubeData,
            video: videoData,
            audio: audioData,
          });

          if (payload.id) {
            mutUpdatePost({ payload: post_payload, id: payload.id });
          } else mutCreatePost(post_payload);

          reset();
        })}
      >
        <Grid justify={"flex-start"} align="center">
          <Grid.Col span={6}>
            <Select
              label="Channel"
              placeholder="Select Channel"
              value={values.channel_id?.toString()}
              data={
                Array.isArray(props.channels)
                  ? props.channels.map(
                      (item: { channel_id: Number; name: string }) => ({
                        value: item.channel_id.toString(),
                        label: item.name,
                      })
                    )
                  : []
              }
              onChange={(value) => setFieldValue("channel_id", Number(value))}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MultiSelect
              label="Hashtags"
              placeholder="select hashtags"
              value={values.hashtags as string[]}
              data={
                Array.isArray(props.hashtagData)
                  ? props.hashtagData.map(
                      (item: { id: Number; hashtag: string }) => ({
                        value: item.hashtag,
                        label: item.hashtag,
                      })
                    )
                  : []
              }
              onChange={(value) => setFieldValue("hashtags", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MultiSelect
              label="Post Categories"
              placeholder="select post-categories"
              value={values.post_category as string[]}
              data={
                Array.isArray(props.categories)
                  ? props.categories.map(
                      (item: { id: Number; category_name: string }) => ({
                        value: item.category_name,
                        label: item.category_name,
                      })
                    )
                  : []
              }
              onChange={(value) => setFieldValue("post_category", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Channel subscribed ?"
              checked={values.channel_subscribed}
              onChange={(event) =>
                setFieldValue("channel_subscribed", event.currentTarget.checked)
              }
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Select
              label="Link Open"
              placeholder="select an option"
              value={values.link_open}
              data={[
                { value: "none", label: "None" },
                { value: "inside", label: "Inside" },
                { value: "outside", label: "Outside" },
                { value: "whatsapp", label: "WhatsApp" },
              ]}
              onChange={(value) => setFieldValue("link_open", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Description"
              placeholder="Description"
              value={values.description}
              onChange={(event) =>
                setFieldValue("description", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Post Link"
              placeholder="Post Link"
              value={values.link}
              onChange={(event) =>
                setFieldValue("link", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Post Type"
              placeholder="post type"
              value={values.post_type}
              data={postType}
              onChange={(value) => setFieldValue("post_type", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share Text"
              placeholder="Share Text"
              value={values.share_text}
              onChange={(event) =>
                setFieldValue("share_text", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share Link"
              placeholder="Share Link"
              value={values.share_link}
              onChange={(event) =>
                setFieldValue("share_link", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share Image"
              placeholder="Share Image"
              value={values.share_image}
              onChange={(event) =>
                setFieldValue("share_image", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DatePicker
              placeholder="select date"
              label="Created At"
              inputFormat="DD/MM/YYYY"
              value={values.created_at ? new Date(values.created_at) : null}
              onChange={(value) => {
                setFieldValue("created_at", dayjs(value).format("YYYY-MM-DD"));
              }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Button Text"
              placeholder="button text"
              value={values.button_text}
              onChange={(event) =>
                setFieldValue("button_text", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Button Color"
              placeholder="button color"
              value={values.button_color}
              onChange={(event) =>
                setFieldValue("button_color", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Image Size"
              placeholder="select an option"
              value={values.image_size}
              data={[
                { value: "1", label: "1x" },
                { value: "2", label: "2x" },
                { value: "3", label: "3x" },
              ]}
              onChange={(value) => setFieldValue("image_size", value ?? "")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Listing Order"
              placeholder="listing order"
              value={values.listing_order}
              onChange={(value) => setFieldValue("listing_order", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Base Likes"
              placeholder="base likes"
              value={values.like_base_value}
              onChange={(value) => setFieldValue("like_base_value", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Base Shares"
              placeholder="base shares"
              value={values.share_base_value}
              onChange={(value) => setFieldValue("share_base_value", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Base Saves"
              placeholder="base_saves"
              value={values.save_base_value}
              onChange={(value) => setFieldValue("save_base_value", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Publish  ?"
              checked={values.publish}
              onChange={(event) =>
                setFieldValue("publish", event.currentTarget.checked)
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text size="md" weight={500}>
              Post Image
            </Text>
          </Grid.Col>
          <Grid.Col span={10}>
            <TextInput
              placeholder="image url"
              value={image_url}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange("image_url", e.target.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              variant="light"
              onClick={(e: SyntheticEvent) => onSetNormalPosts()}
            >
              Add
            </Button>
          </Grid.Col>
          {postImageData.map((item, index) => (
            <>
              <Grid.Col span={8} key={item}>
                <TextInput
                  key={item + 10}
                  id={index.toString()}
                  placeholder="image url"
                  value={item}
                  onChange={() => {}}
                />
              </Grid.Col>
              <Grid.Col span={4} key={item + 15}>
                <ActionIcon
                  onClick={(e: SyntheticEvent) => onRemoveNormalPost(item)}
                  key={item + 20}
                >
                  <Archive
                    size={30}
                    strokeWidth={2}
                    color={"red"}
                    key={item + 22}
                  />
                </ActionIcon>
              </Grid.Col>
            </>
          ))}

          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text size="md" weight={500}>
              Youtube
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              placeholder="thumbnail"
              value={youtubeData.thumbnail}
              onChange={(event) =>
                onFieldChange("youtubeData", {
                  ...youtubeData,
                  thumbnail: event.currentTarget.value,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              placeholder="youtube link"
              value={youtubeData.youtube_link}
              onChange={(event) =>
                onFieldChange("youtubeData", {
                  ...youtubeData,
                  youtube_link: event.currentTarget.value,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text size="md" weight={500}>
              Video
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              placeholder="thumbnail"
              value={videoData.thumbnail}
              onChange={(event) =>
                onFieldChange("videoData", {
                  ...videoData,
                  thumbnail: event.currentTarget.value,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              placeholder="video link"
              value={videoData.video_link}
              onChange={(event) =>
                onFieldChange("videoData", {
                  ...videoData,
                  video_link: event.currentTarget.value,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text size="md" weight={500}>
              Audio
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              placeholder="thumbnail"
              value={audioData.thumbnail}
              onChange={(event) =>
                onFieldChange("audioData", {
                  ...audioData,
                  thumbnail: event.currentTarget.value,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              placeholder="audio link"
              value={audioData.audio_link}
              onChange={(event) =>
                onFieldChange("audioData", {
                  ...audioData,
                  audio_link: event.currentTarget.value,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              placeholder="duration"
              value={audioData.duration}
              onChange={(event) =>
                onFieldChange("audioData", {
                  ...audioData,
                  duration: event.currentTarget.value,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="University"
              placeholder="select university"
              value={values.university}
              data={findUniversities()}
              onChange={(value) => setFieldValue("university", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="course"
              placeholder="select course"
              value={values.course}
              data={findCourses()}
              onChange={(value) => setFieldValue("course", value)}
              disabled={!values?.university}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Stream"
              placeholder="select stream"
              value={values.stream}
              data={findStreams()}
              onChange={(value) => setFieldValue("stream", value)}
              disabled={!values?.course}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Semester"
              placeholder="select semester"
              value={values.semester}
              data={findSemesters()}
              onChange={(value) => setFieldValue("semester", value)}
              disabled={!values?.stream}
            />
          </Grid.Col>
          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={reset}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default FeedForm;
